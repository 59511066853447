export default {

	init( form = null ) {

		function ContactForm( container ) {

			var fields;

			var submit = container.querySelector('.contactForm__submit');
			var inputFile = container.querySelector('.formField--file input');

			var messageSend = false;
			var uploadFileBase64 = null;

			var input;
			var empty;
			var requestData = [];

			var callbackEmpty = container.getAttribute('data-empty');
			var callbackError = container.getAttribute('data-error');
			var callbackSuccess = container.getAttribute('data-success');

			var callbackErrorFileExtended = container.getAttribute('data-errorFileExtended');
			var callbackErrorFileSize = container.getAttribute('data-errorFileSize');
			var callbackErrorFileUpload = container.getAttribute('data-errorFileUpload');

			function removeCallBack( message, status ) {

				var callback = container.querySelectorAll('.contactForm__callback');

				if( callback.length > 0 ) {

					callback.forEach( function(single, index) {

						single.remove();

					});	

				}

				if( message && status ) {

					submit.insertAdjacentHTML('beforebegin', '<p class="contactForm__callback '+status+'">'+message+'</p>' );

				}

			}

			function fileValidation( inputFile ) {

				if( inputFile.value ) { // check if file addedd

					var allowedExtension = ['pdf','doc','docx'];


					if( allowedExtension.indexOf(inputFile.value.split('.')[1]) == -1 ) {

						inputFile.classList.add('empty');
						removeCallBack(callbackErrorFileExtended,'error');

					} else if( inputFile.files[0].size > 5242880 ) {

						inputFile.classList.add('empty');
						removeCallBack(callbackErrorFileSize,'error');

					} else {

						// file -> base64
						var reader = new FileReader();

						reader.readAsDataURL(inputFile.files[0]);

						reader.onload = function( back ) {

							if( back.target.result ) {

								uploadFileBase64 = back.target.result;
								submit.classList.remove('disable');
								inputFile.classList.remove('empty');

								removeCallBack();

							} 

						};

						reader.onerror = function(back){

							console.log('Error: ', back);
							inputFile.classList.add('empty');							
							removeCallBack(callbackErrorFileUpload,'error');

						};	


					}

				}

			}

			
			// CheckFile !
			if( inputFile ) {

				inputFile.addEventListener('change', function(e) {

					submit.classList.add('disable');
					uploadFileBase64 = null;

					fileValidation( inputFile );

				});

			}


			// SubmitForm 
			submit.addEventListener('click',function( e ) {

				e.preventDefault();

				if( !submit.classList.contains('disable') && !messageSend ) {

					submit.classList.add('disable');

					// -- getEmpty -- //

						fields = container.querySelectorAll('.formField--required');

						fields.forEach( function( field, index ) {

							input = field.querySelector('.formField__input');

							if( input.getAttribute('type') == 'file' ) {

								if( !input.value ) {

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

							} else if( input.getAttribute('type') == 'checkbox' ) {

								if( !input.checked ) {

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

							} else {

								if( !input.value ) {

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

							}

						});

					// -- END -- //


					empty = container.querySelectorAll('.empty');

					if( empty.length == 0 ) {

						// -- requestData -- //

							var formData = new FormData();

							formData.append( 'action', 'sendMail' );
							formData.append( 'security', container.querySelector('input#authorize').value );

							container.querySelectorAll('.formField').forEach( function( field , index ) {

								input = field.querySelector('.formField__input');

								if( input.getAttribute('type') != 'checkbox' ) {

									formData.append( input.getAttribute('name'), input.value );

								}

							});	

							if( container.classList.contains('formAplication') ) {

								formData.append( 'formType' , 'aplication' );
								formData.append( 'job' , container.closest('.jobOfferSingle').querySelector('.col__title').textContent );								

								if( uploadFileBase64 && uploadFileBase64!==null ) {

									formData.append( 'attachment' , uploadFileBase64 );
									formData.append( 'attachmentName', document.querySelector('.formField--file input').files[0].name );
									formData.append( 'attachmentType', document.querySelector('.formField--file input').files[0].type );


								}

							} else {

								formData.append( 'formType' , 'contact' );

							}

						// -- END -- //



						// -- request -- //

							var request = new XMLHttpRequest();

							request.onreadystatechange = function() {

								if( request.responseText ) {

									var response = JSON.parse(request.responseText);
									console.log(response);
									if( response.status == 'success' ) {

										removeCallBack(callbackSuccess, 'success');

									} else if( response.status == 'error' ) {

										removeCallBack(callbackError, 'error');

									}

								}

							}

							// Set up our request
							request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

							// Add the required HTTP header for form data POST requests
							// request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

							// Finally, send our data.
							request.send(formData);
							messageSend = true;

						// -- END -- //

					} else {

						removeCallBack( callbackEmpty, 'error' );					

						submit.classList.remove('disable');

					}

				}	

			});

		}

		if( form ) {

			ContactForm( form );

		} else {

			var container = document.querySelectorAll('form.contactForm');

			if( container.length > 0 ) {

				container.forEach( function( box, index ) {

					ContactForm( box );

				});

			}

		}

	},
	finalize() {

	}

} 