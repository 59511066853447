import Hammer from 'hammerjs/hammer';

export default {
	init() {

		function SliderFullScreen( container ) {

			var carusel = container.querySelector('.sliderFullScreen--carusel');
			var slider = container.querySelectorAll('.sliderFullScreen__single');
			var points = container.querySelectorAll('.points__single');

			var slideTime = 4000;

			var counter = 0;
			var slideMax = slider.length-1;
			var windowWidth = window.innerWidth;
			var loop;

			var currentTransform;

			function CurrentSlide() {

				carusel.style.transform = 'translateX('+-(counter*windowWidth)+'px)';		

			}

			function NextSlide() {

				counter += 1;

				if( counter > slideMax ) {

					counter = 0;

				}

				points.forEach( function( point, index ) {

					if( index == counter ) {

						point.classList.add('active');

					} else {

						point.classList.remove('active');

					}

				});

				carusel.style.transform = 'translateX('+-(counter*windowWidth)+'px)';

			};	

			function PrevSlide() {

				counter -= 1;

				if( counter < 0 ) {

					counter = slideMax;

				}

				points.forEach( function( point, index ) {

					if( index == counter ) {

						point.classList.add('active');

					} else {

						point.classList.remove('active');

					}

				});

				carusel.style.transform = 'translateX('+-(counter*windowWidth)+'px)';

			};

			function StartLoop() {

				loop = setInterval(
					function(){NextSlide()},
					slideTime
				);

			};

			function EndLoop(){

				clearInterval(loop);

			};

			function GetTransform() {

				var style = window.getComputedStyle(carusel);
				var matrix = new WebKitCSSMatrix(style.webkitTransform);
				return matrix.m41;

			}

			// -- CLICK - //

				points.forEach( function( point, index ) {

					point.addEventListener('click', function(){

						EndLoop();

						counter = index-1;

						points.forEach( function( point2, index2 ) {

							if( index != index2 ) {

								point2.classList.remove('active');

							} else {

								point2.classList.add('active');

							}

						});

						NextSlide();

						StartLoop();

					});

				});

			// -- END -- //


			// -- HAMMER -- //

				var mc = new Hammer( 
					container.getElementsByClassName('sliderFullScreen--carusel')[0],
					{
						inputClass: Hammer.TouchMouseInput
					} 
				);

				// -- SWIPE

					// mc.on('swipeleft', function(e) { // next
					// 	console.log('swipeleft');

					// 	if( Math.abs( e.velocityX ) > 1 ) {

					// 		console.log(e);
					// 		EndLoop();

					// 		NextSlide();

					// 		StartLoop();

					// 	}						

					// });

					// mc.on('swiperight', function(e) { // prev
					// 	console.log('swiperight');
					// 	if( Math.abs( e.velocityX ) > 1 ) {

					// 		console.log(e);
					// 		EndLoop();

					// 		PrevSlide();

					// 		StartLoop();

					// 	}
						
					// });

				// -- END


				// -- PAN

					mc.on('panstart', function(e) {

						carusel.classList.add('notrasition');
						currentTransform = GetTransform();
						EndLoop();	

					});

					mc.on('panleft', function(e) {

						carusel.style.transform = 'translateX('+ (currentTransform+e.deltaX) +'px)';	

					});


					mc.on('panright', function(e) {	

						carusel.style.transform = 'translateX('+ (currentTransform+e.deltaX) +'px)';						

					});

					mc.on('panend', function(e) {

						carusel.classList.remove('notrasition');

						if( e.deltaX < 0 ) { // -> right	next				

							if( e.deltaX < -(windowWidth/5) ) {

								NextSlide();

							} else {	

								CurrentSlide();

							}

						} else { // <- left		 prev						

							if( e.deltaX > ( windowWidth/5 ) ) {

								PrevSlide();

							} else {

								CurrentSlide();

							}

						}

						StartLoop();

					});

				// -- END


			// -- END -- //

			window.addEventListener('resize', function(){ 

				windowWidth = window.innerWidth;
				CurrentSlide();
				
			});

			StartLoop();

		}

		var container = document.querySelectorAll('.sliderFullScreen');	

		if( container.length > 0 ) {

			container.forEach( function( box,index ) {

				SliderFullScreen(box);	

			});				

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
