export default {
	init() {

		function BarCheckOpen ( container ) {

			var openedContainer = container.querySelector('.bar__opened');
			var openedStatus = container.querySelector('.opened__status');

			// -- OPEN -- //

				var openHour = parseInt( container.getAttribute('data-open').split(':')[0] );
				var openMinut = parseInt( container.getAttribute('data-open').split(':')[1] ) + ( openHour * 60 );
				var openSec = openMinut * 60;

			// -- END -- //


			// -- CLOSE -- // 

				var closeHour = parseInt( container.getAttribute('data-close').split(':')[0] );
				var closeMinut = parseInt( container.getAttribute('data-close').split(':')[1] ) + ( closeHour * 60 );
				var closeSec = closeMinut * 60;

			// -- END -- //


			// -- CURRENT -- //

				var date = new Date;
				var currentHour =  date.getHours();
				var currentMinut = date.getMinutes() + ( currentHour * 60 );
				var currentSec = currentMinut * 60;

			// -- END -- //


			if( openSec < currentSec && closeSec > currentSec ) {

				openedContainer.classList.remove('close');
				openedContainer.classList.add('open');
				openedStatus.textContent = 'otwarte';

			} else {

				openedContainer.classList.remove('open');
				openedContainer.classList.add('close');
				openedStatus.textContent = 'zamknięte';

			}

		}

		var container = document.querySelectorAll('.bar');	

		if( container.length > 0 ) {

			container.forEach( function( box,index ) {

				BarCheckOpen(box);	

			});				

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
