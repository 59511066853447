// import jQuery from 'jquery';
import './style.scss';

import LazyLoad from './lazyLoad/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Components
import ContactForm from '../src/Components/ContactForm/ContactForm';
import Cookie from '../src/Components/Cookie/Cookie';
import RodoPopup from '../src/Components/RodoPopup/RodoPopup';

// Sections
import Bar from '../src/Sections/Bar/Bar';
import SliderFullScreen from '../src/Sections/SliderFullScreen/SliderFullScreen';
import PartnersLogo from '../src/Sections/PartnersLogo/PartnersLogo';
import GoogleMaps from '../src/Sections/GoogleMaps/GoogleMaps';
import HeadAlert from '../src/Sections/HeadAlert/HeadAlert';

// pages
import ArchiveJobOffers from '../src/Pages/ArchiveJobOffers/ArchiveJobOffers';


LazyLoad.init();

document.addEventListener("DOMContentLoaded", function() {
    
    Subpage.init();

    // components
    Cookie.init();
    RodoPopup.init();
    ContactForm.init();

    //sections
    Bar.init();
    SliderFullScreen.init();
    PartnersLogo.init();
    GoogleMaps.init();
    HeadAlert.init();

    //pages
    ArchiveJobOffers.init();
    
});
