import Form from  '../../Components/ContactForm/ContactForm';
import $ from 'jquery';

export default {

	init() {

		function ArchiveJobOffers( container ) {

			// -- clone ContactForm -- //

				var contactForm;

				if( container.querySelector('.contactForm') ) {

					contactForm = container.querySelector('.archiveJob__contactForm').cloneNode(true);

					container.querySelector('.archiveJob__contactForm').remove();

				}

			// -- End -- //


			var offers = container.querySelectorAll('.jobOfferSingle');
			var offerFormHeight;

			if( contactForm ) {

				offers.forEach( function( single, index ) {

					single.querySelector('.col__submit').addEventListener('click', function() {

						offerFormHeight = 0;

						if( !single.querySelector('.jobOfferSingle__form .contactForm') ) {

							// -- inject form
								single.querySelector('.jobOfferSingle__form .form__label').insertAdjacentHTML('afterend', contactForm.innerHTML);

							// -- remove class hide -> show form
								single.querySelector('.jobOfferSingle__form').classList.remove('hide');
								single.querySelector('.jobOfferSingle__form').classList.remove('startHeight');


							// -- calc height form
								offerFormHeight = parseFloat( getComputedStyle( single.querySelector('.jobOfferSingle__form .contactForm') ).height.replace('px','') );
								offerFormHeight += parseFloat( getComputedStyle( single.querySelector('.form__label') ).height.replace('px','')  );
								offerFormHeight += parseFloat( getComputedStyle( single.querySelector('.form__label') ).marginBottom.replace('px','') );							


								single.querySelector('.jobOfferSingle__form').style.height = offerFormHeight +'px';

							// -- set height auto -> no responsive recalc height -- //

								setTimeout( 
									function() {

										single.querySelector('.jobOfferSingle__form').style.height = 'auto';

									},
									300 
								);

							// -- init form action -- //
								Form.init( single.querySelector('.jobOfferSingle__form .contactForm') );

						} 

						// scroll to contactForm
							$('html ,body').animate(
								{'scrollTop': (single.querySelector('.jobOfferSingle__form').offsetTop - parseFloat(getComputedStyle( single.querySelector('.jobOfferSingle__form') ).marginTop.replace('px','') ) - document.querySelector('header').offsetHeight) }, 
								'smooth'
							);	

						

					});

				});

			} else {

				offers.forEach( function( single, index ) {

					single.querySelector('.col__submit').remove();

				});

			}

		}

		var container = document.querySelector('.archiveJob');

		if( container ) {

			ArchiveJobOffers( container );

		}

	},
	finalize() {

	}

}