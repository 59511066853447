export default {
	init() {
		// 52.313254, 21.184383
		function RenderGoogleMap( container, lati, long ) {

			var markerIconUrl = window.location.origin + '/wp-content/themes/mm/img/png/pin@2x.png';
			    
		    var mapOptions = {
		        zoom: 15,
		        center: new google.maps.LatLng(lati, long), // New York
		        mapTypeControl: false,
		        streetViewControl: false,
		        fullscreenControl : false,
		        styles: [
				    {
				        "featureType": "water",
				        "elementType": "all",
				        "stylers": [
				            {
				                "hue": "#7fc8ed"
				            },
				            {
				                "saturation": 55
				            },
				            {
				                "lightness": -6
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "hue": "#7fc8ed"
				            },
				            {
				                "saturation": 55
				            },
				            {
				                "lightness": -6
				            },
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.park",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#83cead"
				            },
				            {
				                "saturation": 1
				            },
				            {
				                "lightness": -15
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#f3f4f4"
				            },
				            {
				                "saturation": -84
				            },
				            {
				                "lightness": 59
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "hue": "#ffffff"
				            },
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 100
				            },
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#ffffff"
				            },
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 100
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "hue": "#bbbbbb"
				            },
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 26
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#ffcc00"
				            },
				            {
				                "saturation": 100
				            },
				            {
				                "lightness": -35
				            },
				            {
				                "visibility": "simplified"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#ffcc00"
				            },
				            {
				                "saturation": 100
				            },
				            {
				                "lightness": -22
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.school",
				        "elementType": "all",
				        "stylers": [
				            {
				                "hue": "#d7e4e4"
				            },
				            {
				                "saturation": -60
				            },
				            {
				                "lightness": 23
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    }
				]

		    };
		    
		    var mapElement = container.querySelector('.googleMaps__map');

		    var map = new google.maps.Map(mapElement, mapOptions);

		    var mainMarkerIcon = { 

		    	url: markerIconUrl,
		    	scaledSize: new google.maps.Size(84, 84), // scaled size
			    origin: new google.maps.Point(0, -5), // origin
			    anchor: new google.maps.Point(42, 84) // anchor

		    };

		    var marker = new google.maps.Marker({
		        position: new google.maps.LatLng(lati, long),
		        title: 'Multi-Medica',
		        map: map,
		        icon : mainMarkerIcon,
		        
		    });
			
		    if( container.getAttribute('data-url') ) {

			    google.maps.event.addListener(marker, 'click', function() {
			    	
			    	window.open( container.getAttribute('data-url') , '_blank');

				});

			}

		}

		var container = document.querySelectorAll('section.googleMaps');

		if( container.length > 0 ) {

			container.forEach( function( box , index ) {

				if( box.getAttribute('data-long') && box.getAttribute('data-lati') ) {

					RenderGoogleMap( box, box.getAttribute('data-lati'), box.getAttribute('data-long'));

				}

			});

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
