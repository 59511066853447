import lozad from 'lozad';

const LazyLoad = new function() {

    this.init = function (node) {

        var pictureObserverAfterLoadDOM;

		window.addEventListener("load", function () {
			//lazy-load-ar - after DOM ready (doładowywanie assetów wraz ze scrollem dopiero po wyrenderowaniu strony)
			pictureObserverAfterLoadDOM = lozad('.lazy-load-ar', {
			    threshold: 0
			})
			pictureObserverAfterLoadDOM.observe();
		});

    }
};
export default LazyLoad;
