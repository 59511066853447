export default {
	init() {

		function PartnersLogoCarusel ( container ) {

			var carusel = container.querySelector('.carusel__content');

			var buttonNext = container.querySelector('.carusel__button--next');
			var buttonPrev = container.querySelector('.carusel__button--prev');

			var logo = container.querySelectorAll('.content__logo');
			var logoWidth;
			var logoOnPage;

			var current = 0;

			var interval;
			var intervalTime = 3000;

			// -- FUNCTONS -- //

				function SetPosition(){
					
					carusel.style.transform = 'translateX('+ -(current*logoWidth) +'px)';

				}

				function NextSlide() {

					current += 1;

					if( (current + logoOnPage) > logo.length ) {

						current = 0;

					}

					SetPosition();

				}

				function PrevSlide() {

					current -= 1;

					if( current < 0 ) {

						current = logo.length - logoOnPage;

					}

					SetPosition();

				}

				function init() {

					logoWidth = logo[0].getBoundingClientRect().width;
					
					logoOnPage = parseInt( (carusel.getBoundingClientRect().width / logoWidth).toPrecision(1) );

					SetPosition();					

				}

			// -- END -- //



			// -- LOOP -- //

				function StartLoop() {

					interval = setInterval(
						function(){NextSlide()},
						intervalTime
					);

				};

				function EndLoop(){

					clearInterval(interval);

				}

			// -- END - //



			// -- BUTTONS -- //

				buttonNext.addEventListener('click',function(){
					EndLoop();

					NextSlide();

					StartLoop();
					
				});

				buttonPrev.addEventListener('click', function(){
					
					EndLoop();

					PrevSlide();

					StartLoop();

				});

			// -- END -- //

			window.addEventListener('resize', function() {

				init();

			});

			init();
			StartLoop();

		}

		var container = document.querySelectorAll('.partnersLogo');	

		if( container.length > 0 ) {

			container.forEach( function( box,index ) {

				PartnersLogoCarusel(box);	

			});				

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
