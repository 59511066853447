export default {
	init() {
    	
		// -- MainMenuTop -- //

			function MouseEnter( subMenu ) {

				var subMenuItems = subMenu.querySelectorAll('.subMenu__content li');
				var subMenuLayout = subMenu.querySelectorAll('.subMenu2 .subMenu2__box');

				subMenuItems.forEach( function( subItem,index ) {

					subItem.addEventListener('mouseenter', function(){

						subMenuItems.forEach( function( subItem2,index2 ) {

							subItem2.classList.remove('active');

						});

						subMenuLayout.forEach( function( layout,index3 ) {

							layout.classList.remove('active');

						});

						subMenuItems[index].classList.add('active');
						subMenuLayout[index].classList.add('active');

					});

				});

			}

			function MainMenuTop() {

				var header = document.querySelector('header');
				var itemsBox = header.querySelectorAll('.header__menu .itemBox');

				itemsBox.forEach( function( submenu, index ) {

					new MouseEnter(submenu);

				})

			}

			function MainMenuTopDisableBoxMainLink() {

				var headerMenu = document.querySelector('header .header__menu');
				var itemsBox = headerMenu.querySelectorAll('.itemBox');

				itemsBox.forEach( function( box, index ) {

					box.querySelector('.menuItem').addEventListener('click', function(e) {

						e.preventDefault();

					});

				});

			}

			MainMenuTopDisableBoxMainLink();
			MainMenuTop();

		// -- END -- //



		// -- MainMenuMobile -- //

			function MainMenuMobile() {

				var hamburger = document.querySelector('header .header__hamburger button');

				var container = document.querySelector('.mobileMenu');

				var menu = container.querySelector('nav');

				// -- Open

					hamburger.addEventListener('click', function() {

						if( container.classList.contains('active') ) {

							container.classList.remove('active');

						} else {

							container.classList.add('active');

						}

					});

				// -- Close

					container.addEventListener('click',function() {

						container.classList.remove('active');

					});

					menu.addEventListener('click',function(e) {
						e.stopPropagation();
					});

				// -- END

			}


			function MainMenuMobileSub() {

				function calcSubMenu2Height( items ) {

					var tempHeight = 0;

					items.forEach( function( item, index ) {

						tempHeight += item.offsetHeight ;//+ parseFloat( getComputedStyle(item).paddingBottom.replace('px','') ) + parseFloat( getComputedStyle(item).marginTop.replace('px','') );

					});

					return tempHeight;

				}

				var container = document.querySelector('.mobileMenu');

				var mobileMenu = container.querySelector('.mobileMenu__content');

				// -- Disable links Submenu Leve1 -- //

					var subMenu1Links = mobileMenu.querySelectorAll('.subMenuItem__link--parent');

					subMenu1Links.forEach( function( item, index ) {

						item.addEventListener('click',function(e) {

							e.preventDefault();

						});

					});

				// -- End -- //


				// -- StopPropgation link SubMenu2 -- //

					mobileMenu.querySelectorAll('.subMenu2Item').forEach( function( item, index ) {

						item.addEventListener('click', function(e){

							e.stopPropagation();

						});

					});

				// -- END -- // 


				// -- Open SubMenu 2 -- // 

					var subMenu1Li = mobileMenu.querySelectorAll('.subMenuItem');
					var subMenu2TempHeight;
					var subMenu2Li;

					subMenu1Li.forEach( function( item, index ) {

						item.addEventListener('click', function() {

							subMenu2TempHeight = 0;

							if( item.querySelector('.subMenu2') ) {

								mobileMenu.querySelectorAll('.subMenuItem .subMenu2').forEach( function( menu, index3 ) {

									if( index != index3 ) {

										menu.closest('li.subMenuItem').classList.remove('active');
										
									}

									menu.style.height = 0+'px';

								});

								if( !item.classList.contains('active') ) {

									item.classList.add('active');

									subMenu2TempHeight = calcSubMenu2Height( item.querySelectorAll('.subMenu2 li') );

									item.querySelector('.subMenu2').style.height = subMenu2TempHeight +'px';

								} else {

									item.classList.remove('active');

								}								

							} else {

								window.location.href = item.querySelector('.subMenuItem__link').getAttribute('href');

							}

						});

					});

				// -- End -- //  





				// -- Resize SubMenu 2 -- //

					var openMenu;
					var subMenu2;
					var subMenu2Height;

					window.addEventListener('resize', function() {

						subMenu2Height = 0;

						openMenu = mobileMenu.querySelector('.subMenuItem.active');
						
						if( openMenu ) {

							subMenu2 = openMenu.querySelector('.subMenu2');

							if( subMenu2 ) {

								subMenu2Height = calcSubMenu2Height( subMenu2.querySelectorAll('li') );

								subMenu2.style.height = subMenu2Height +'px';
								
							}

						}

					});


				// -- END -- // 

			}

			MainMenuMobile();
			MainMenuMobileSub();

		// -- END -- //

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
